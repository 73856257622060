import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EditorjsparsehtmlModule } from "src/app/directives/editorjsparsehtml/editorjsparsehtml.module";
import { SwiperModule } from "swiper/angular";
import { ItemsModule } from "../items/items.module";
import { BlockBadgeComponent } from "./block-badge/block-badge.component";
import { BlockCardComponent } from "./block-card/block-card.component";
import { BlockCarouselComponent } from "./block-carousel/block-carousel.component";
import { BlockContentComponent } from "./block-content/block-content.component";
import { BlockImageTextComponent } from "./block-image-text/block-image-text.component";
import { BlockMedalComponent } from "./block-medal/block-medal.component";
import { BlockOfferCardComponent } from "./block-offer-card/block-offer-card.component";
import { BlockPlanCardComponent } from "./block-plan-card/block-plan-card.component";
import { BlockTestimonialComponent } from "./block-testimonial/block-testimonial.component";
import { BlockYoutubeVideoComponent } from "./block-youtube-video/block-youtube-video.component";
import { BlockContactUsComponent } from "./block-contact-us/block-contact-us.component";

import { GoogleMapsModule } from "@angular/google-maps";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgxPaginationModule } from "ngx-pagination";
import { StrapiiconModule } from "src/app/directives/strapiicon/strapiicon.module";
import { StrapiimageModule } from "src/app/directives/strapiimage/strapiimage.module";
import { StrapilinkModule } from "src/app/directives/strapilink/strapilink.module";
import { TooltipModule } from "src/app/directives/tooltip/tooltip.module";
import { SortModule } from "src/app/pipes/sort/sort.module";
import SwiperCore, {
  A11y,
  Autoplay,
  Lazy,
  Navigation,
  Pagination,
  Scrollbar,
  Virtual,
} from "swiper";
import { BotaoTipo1Module } from "../common/botao-tipo1/botao-tipo1.module";
import { BotaoWhatsappModule } from "../common/botao-whatsapp/botao-whatsapp.module";
import { FormsModule } from "../common/forms/forms.module";
import { LoadingModule } from "../common/loading/loading.module";
import { ModalModule } from "../common/modal/modal.module";
import { BlockBlogPostListAndFilterComponent } from "./block-blog-post-list-and-filter/block-blog-post-list-and-filter.component";
import { BlockBudgetFormComponent } from "./block-budget-form/block-budget-form.component";
import { BlockCardStoreComponent } from "./block-card-store/block-card-store.component";
import { BlockFaqComponent } from "./block-faq/block-faq.component";
import { BlockGlossaryComponent } from "./block-glossary/block-glossary.component";
import { BlockLatestBlogPostsComponent } from "./block-latest-blog-posts/block-latest-blog-posts.component";
import { BlockNewsLetterComponent } from "./block-news-letter/block-news-letter.component";
import { BlockPlanTableComponent } from "./block-plan-table/block-plan-table.component";
import { BlockProductDescriptionComponent } from "./block-product-description/block-product-description.component";
import { BlockShopListComponent } from "./block-shop-list/block-shop-list.component";
import { BlockShopMapComponent } from "./block-shop-map/block-shop-map.component";
import { AfterIfModule } from "src/app/directives/afterif/afterif.module";
import { DateFilterModule } from "src/app/pipes/date-filter/date-filter.module";
import { BlockSeminovosSearchBarComponent } from "./block-seminovos-search-bar/block-seminovos-search-bar.component";
import { ReactiveFormsModule, FormsModule as fm } from "@angular/forms";
import { BlockContactFormModule } from "./block-contact-form/block-contact-form.module";
import { ContentModule } from "../items/content/content.module";
import { BlockCarouselFormComponent } from "./block-carousel-form/block-carousel-form.component";
import { BlockFiltroVeiculosComponent } from "./block-filtro-veiculos/block-filtro-veiculos.component";
import { BlockBannerComponent } from "./block-banner/block-banner.component";
import { BlockAccordionComponent } from "./block-accordion/block-accordion.component";
import { BlockImageComponent } from "./block-image/block-image.component";
import { BlockCardSimuladorComponent } from "./block-card-simulador/block-card-simulador.component";
import { BlockOfferSempreNovoComponent } from "./block-offer-sempre-novo/block-offer-sempre-novo.component";
import { VeiculoModeloCardComponent } from "src/app/components/blocks/block-offer-sempre-novo/veiculo-modelo-card/veiculo-modelo-card.component";
import { BlockFiltroSempreNovoComponent } from "src/app/components/blocks/block-filtro-sempre-novo/block-filtro-sempre-novo.component";
import { BlockModeloDetalheComponent } from "src/app/components/blocks/block-modelo-detalhe/block-modelo-detalhe.component";
import { BlockFiltroDestaqueComponent } from "./block-filter-destaque/block-filter-destaque.component";
import { SafeModule } from "src/app/pipes/safe/safe.module";
import { BlockImageListComponent } from "./block-image-list/block-image-list.component";
import { MarkdownModule } from "ngx-markdown";
import { BlockGridCardComponent } from "./block-grid-card/block-grid-card.component";
import { BlockBannerTextComponent } from "./block-banner-text/block-banner-text.component";

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Lazy,
  Virtual,
]);
@NgModule({
  declarations: [
    BlockContentComponent,
    BlockBadgeComponent,
    BlockCardComponent,
    BlockCarouselComponent,
    BlockImageTextComponent,
    BlockMedalComponent,
    BlockPlanCardComponent,
    BlockTestimonialComponent,
    BlockYoutubeVideoComponent,
    BlockOfferCardComponent,
    BlockOfferSempreNovoComponent,
    VeiculoModeloCardComponent,
    BlockFaqComponent,
    BlockShopMapComponent,
    BlockNewsLetterComponent,
    BlockContactUsComponent,
    BlockGlossaryComponent,
    BlockBudgetFormComponent,
    BlockBlogPostListAndFilterComponent,
    BlockPlanTableComponent,
    BlockProductDescriptionComponent,
    BlockCardStoreComponent,
    BlockLatestBlogPostsComponent,
    BlockShopListComponent,
    BlockSeminovosSearchBarComponent,
    BlockCarouselFormComponent,
    BlockFiltroVeiculosComponent,
    BlockFiltroSempreNovoComponent,
    BlockModeloDetalheComponent,
    BlockBannerComponent,
    BlockAccordionComponent,
    BlockImageComponent,
    BlockCardSimuladorComponent,
    BlockFiltroDestaqueComponent,
    BlockGridCardComponent,
    BlockImageListComponent,
    BlockBannerTextComponent,
  ],
  exports: [
    BlockContentComponent,
    BlockBadgeComponent,
    BlockCardComponent,
    BlockCarouselComponent,
    BlockImageTextComponent,
    BlockMedalComponent,
    BlockPlanCardComponent,
    BlockTestimonialComponent,
    BlockYoutubeVideoComponent,
    BlockOfferCardComponent,
    BlockOfferSempreNovoComponent,
    BlockFaqComponent,
    BlockShopMapComponent,
    BlockNewsLetterComponent,
    BlockContactUsComponent,
    BlockGlossaryComponent,
    BlockBudgetFormComponent,
    BlockBlogPostListAndFilterComponent,
    BlockPlanTableComponent,
    BlockProductDescriptionComponent,
    BlockCardStoreComponent,
    BlockLatestBlogPostsComponent,
    BlockShopListComponent,
    BlockFiltroSempreNovoComponent,
    BlockModeloDetalheComponent,
    BlockSeminovosSearchBarComponent,
    ItemsModule,
    SwiperModule,
    BlockContactFormModule,
    fm,
    ContentModule,
    BlockCarouselFormComponent,
    BlockFiltroVeiculosComponent,
    BlockBannerComponent,
    BlockAccordionComponent,
    BlockImageComponent,
    BlockCardSimuladorComponent,
    BlockFiltroDestaqueComponent,
    BlockImageListComponent,
    BlockGridCardComponent,
    BlockBannerTextComponent,
  ],
  imports: [
    DateFilterModule,
    AfterIfModule,
    ModalModule,
    SortModule,
    GoogleMapsModule,
    LoadingModule,
    NgxPaginationModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    BotaoTipo1Module,
    BotaoWhatsappModule,
    StrapilinkModule,
    StrapiiconModule,
    StrapiimageModule,
    EditorjsparsehtmlModule,
    CommonModule,
    ItemsModule,
    TooltipModule,
    SwiperModule,
    BlockContactFormModule,
    fm,
    ContentModule,
    SafeModule,
    MarkdownModule.forRoot(),
  ],
})
export class BlocksModule {}
