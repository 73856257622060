import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { BlockGridCard } from "src/models/zeus/block/block-grid-card";

@Component({
  selector: "app-block-grid-card",
  templateUrl: "./block-grid-card.component.html",
  styleUrls: ["./block-grid-card.component.scss"],
})
export class BlockGridCardComponent implements OnInit {
  apiUrl = environment.API_URL;
  @Input() props: BlockGridCard = {
    title: "",
    cards: [],
  };
  constructor() {}

  ngOnInit() {}
}
