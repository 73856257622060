import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { BlockImageList } from "src/models/zeus";

@Component({
  selector: "app-block-image-list",
  templateUrl: "./block-image-list.component.html",
  styleUrls: ["./block-image-list.component.scss"],
})
export class BlockImageListComponent implements OnInit {
  apiUrl = environment.API_URL;
  @Input() props: BlockImageList = {
    image: {
      data: {
        attributes: {
          url: "",
        },
      },
    },
    backgroundColor: "gray",
    imageBeforeText: true,
    list: [],
    afterText: "",
    title: "",
  };

  constructor() {}

  ngOnInit() {}
}
