import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { debounceTime, delay, fromEvent, of, Subscription, tap } from "rxjs";
import { TradutorService } from "src/app/services/tradutor.service";
import { ICard } from "src/models/zeus";
import { SwiperComponent } from "swiper/angular";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent implements AfterViewInit, OnDestroy {
  public subscription!: Subscription;
  public heightSubscription!: Subscription;
  public resizeHeightSubscription!: Subscription;
  public activeSlide = false;

  @Input() public card!: ICard;
  @Input() souOPrimeiroCard: boolean = false;
  @Output() size = new EventEmitter<number>(true);
  @ViewChild("topo") topo?: ElementRef<HTMLImageElement>;
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;

  constructor(
    public tradutorService: TradutorService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngAfterViewInit(): void {
    if (this.souOPrimeiroCard) {
      const win = this.document.defaultView;
      if (win) {
        this.heightSubscription = fromEvent(win, "resize")
          .pipe(debounceTime(100))
          .subscribe((x) => {
            const altura = this.topo?.nativeElement.offsetHeight;
            this.size.emit(altura);
          });
        this.resizeHeightSubscription = of(true)
          .pipe(
            delay(100),
            tap(() => {
              const altura = this.topo?.nativeElement.offsetHeight;
              if (altura) {
                this.size.emit(altura);
              }
            })
          )
          .subscribe();
      }
    }
  }

  public ngOnDestroy(): void {
    this.heightSubscription?.unsubscribe();
    this.resizeHeightSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  public turnOnSwiper() {
    if (!this.activeSlide) {
      this.activeSlide = true;

      this.subscription = of(true)
        .pipe(
          delay(100),
          tap(() => {
            this.swiper?.swiperRef.slideNext();
          })
        )
        .subscribe();
    }
  }
}
