<div class="container container-grid">
  <h3 class="title">{{ props.title }}</h3>
  <div class="grid">
    <div *ngFor="let card of props.cards" class="card">
      <img [src]="apiUrl + card.icon.data.attributes.url" />
      <h4>{{ card.title }}</h4>
      <div class="description">
        <markdown ngPreserveWhitespaces>
          {{ card.description }}
        </markdown>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap" style="gap: 16px" *ngIf="props.link">
    <app-botao-tipo1
      class="redondo"
      [strapilink]="props.link"
      cor1="var(--white)"
      [cor2]="props.link.color === 'red' ? 'red' : 'var(--primary)'"
      [full]="true"
    ></app-botao-tipo1>
  </div>
</div>
