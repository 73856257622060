<div class="container flex flex-column pt64 pb64">
  <!-- <div class="flex flex-justify-end mb24">
      <a class="share flex flex-justify-start flex-items-center brfull cor-primary s2 udl hv-cor-black pt8 pb8 pl16 pr16"
        href=""><img class="mr16" width="24px" height="24px" src="../../../../assets/images/icon-share.svg"
          alt="Compartilhar">
        <p>Compartilhar</p>
      </a>
    </div> -->
  <div class="flex flex-wrap flex-justify-between w-100" style="gap: 32px 16px">
    <div class="box-esquerdo mr16">
      <div>
        <div
          class="flex flex-items-center flag glass br5 bg-white-translucent"
          style="
            position: absolute;
            right: 16px;
            top: 16px;
            padding: 2px 6px;
            z-index: 2;
          "
        >
          <img
            class="bg-primary"
            style="margin-right: 4px"
            [appStrapiicon]
            icon="ico-label.svg"
            alt=""
            width="18"
            height="18"
          />
          <p style="margin-top: 1px" class="cor-primary s2 fw600 upc">
            {{ veiculo.tipoAquisicao }}
          </p>
        </div>
        <swiper
          [spaceBetween]="10"
          [navigation]="true"
          [thumbs]="{ swiper: thumbsSwiper }"
          class="estilo1 imagem-topo"
        >
          <ng-template swiperSlide *ngIf="veiculoVideoUrl">
            <div style="width: 100%; height: 100%">
              <iframe
                swiperSlide
                [src]="veiculoVideoUrl | safe"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="00135834-c06a-4c41-adc6-3b6f5bee1c8c"
              ></iframe>
            </div>
          </ng-template>
          <ng-template swiperSlide *ngFor="let imagem of imagens">
            <img *ngIf="imagem as url" [src]="url" [alt]="veiculo.modelo" />
            {{ imagem }}
          </ng-template>
        </swiper>
        <swiper
          [config]="swiperConfigMobile"
          (swiper)="thumbsSwiper = $event"
          [spaceBetween]="24"
          [slidesPerView]="4"
          [freeMode]="true"
          [watchSlidesProgress]="true"
          class="imagem-lista mt24"
        >
          <ng-template swiperSlide *ngIf="veiculoVideoUrl">
            <div style="width: 100%; height: 100%">
              <iframe
                swiperSlide
                [src]="veiculoVideoUrl | safe"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="00135834-c06a-4c41-adc6-3b6f5bee1c8c"
              ></iframe>
            </div>
          </ng-template>
          <ng-template swiperSlide *ngFor="let item of imagens">
            <img *ngIf="item as url" [src]="url" [alt]="veiculo.modelo" />
          </ng-template>
        </swiper>
        <ng-container *ngIf="veiculo.valor">
          <div class="box">
            <h2 class="box_title">Compare os preços</h2>
            <div class="box_items">
              <div class="box_item">
                <p class="box_item-title">Valor anunciado</p>
              </div>
              <div class="box_item">
                <h3 class="box_item-ProductPrice">
                  {{ currencyValue(veiculo.valor) }}
                </h3>
              </div>
            </div>
            <hr class="mt24 mb24 w-100" />
            <ng-container *ngIf="veiculo.mediaVamos">
              <div class="box_items">
                <div class="box_item">
                  <p class="box_item-title">Seminovos Vamos</p>
                  <p class="box_description">
                    Valor médio de veículos iguais a esse anunciado na Vamos
                  </p>
                </div>
                <div class="box_item">
                  <h3 class="box_item-price">
                    {{ currencyValue(veiculo.mediaVamos) }}
                  </h3>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="veiculo.precoFipe != 'Sem Preço'">
              <hr class="mt24 mb24 w-100" />
              <div class="box_items">
                <div class="box_item">
                  <p class="box_item-title">FIPE</p>
                  <p class="box_description">
                    Valor deste veículo na tabela Fipe
                  </p>
                </div>
                <div class="box_item">
                  <h3 class="box_item-price">{{ veiculo.precoFipe }}</h3>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="box-direito">
      <h2 class="cor-black fw500 titulo mb16">{{ veiculo.modelo }}</h2>
      <app-botao-whatsapp></app-botao-whatsapp>

      <a
        class="flex flex-items-center pt16 pb32 fw500 udl press cor-black hv-cor-primary s3 link-externo"
        href="https://vamos.bancobbcdigital.com.br/"
        target="_blank"
        title="Simulador Banco BBC Digital e Vamos"
      >
        <img
          class="mr8"
          src="/assets/images/banco-bbc-digital.svg"
          alt=""
          width="24px"
          height="24px"
        />
        Simule as parcelas agora
      </a>

      <table class="w-100 s3" cellspacing="0" cellpadding="0">
        <tr class="hv-bg-white" *ngIf="veiculo.categoria as valor">
          <td>Categoria</td>
          <td>{{ valor }}</td>
        </tr>
        <tr class="hv-bg-white" *ngIf="veiculo.marca as valor">
          <td>Marca</td>
          <td>{{ valor }}</td>
        </tr>
        <tr class="hv-bg-white" *ngIf="veiculo.modelo as valor">
          <td>Modelo</td>
          <td>{{ valor }}</td>
        </tr>
        <tr class="hv-bg-white" *ngIf="veiculo.ano as valor">
          <td>Ano</td>
          <td>{{ valor }}</td>
        </tr>
        <tr class="hv-bg-white" *ngIf="veiculo.eixo as valor">
          <td>Eixo</td>
          <td>{{ valor }}</td>
        </tr>
        <tr class="hv-bg-white" *ngIf="veiculo.km as valor">
          <td>Quilometragem</td>
          <td>{{ valor | number : "1.0-0" }}km</td>
        </tr>
        <tr class="hv-bg-white" *ngIf="veiculo.implementos as valor">
          <td>Implemento</td>
          <td>{{ valor }}</td>
        </tr>
        <tr *ngIf="campanhasTags.length">
          <td>Campanhas</td>
          <td style="display: flex; gap: 10px; flex-wrap: wrap">
            <div
              class="flex flex-items-center glass br5 bg-white-translucent"
              style="padding: 5px"
              *ngFor="let campanha of campanhasTags"
            >
              <img
                class="bg-primary"
                style="margin-right: 4px"
                [appStrapiicon]
                icon="ico-label.svg"
                alt=""
                width="18"
                height="18"
              />
              <p style="margin-top: 1px" class="cor-primary s2 fw600 upc">
                {{ campanha }}
              </p>
            </div>
          </td>
        </tr>
      </table>

      <!-- <ng-container *ngIf="veiculo.observacao as valor">

          <p class="fw500 mt16 s3">Observação:</p>
          <p class="fw400 s1" style="white-space: pre-wrap">{{ valor }}</p>
        </ng-container> -->

      <div
        class="bg-white mt32 mb32 pa32 flex flex-column flex-items-start"
        *ngIf="veiculo.filial as filial"
      >
        <h3
          class="flex flex-wrap flex-justify-between flex-items-center mb24 link-interno cor-primary s4 upc hv-udl"
        >
          {{ filial.nome }}
        </h3>
        <p class="cor-black s4 upc fw500 mb24" *ngIf="filial.endereco as valor">
          {{ valor }}
          <span class="s2 fw400"
            >{{ filial.cep && filial.cep + "." }} <br />
            {{ filial.bairro && filial.bairro + "," }}
            <br />
            {{ filial.cidade }} - {{ filial.uf }}
          </span>
        </p>

        <a
          class="flex cor-black s4 fw500 hv-cor-primary"
          [href]="'tel:' + valor"
          *ngIf="veiculo.filial.telefone as valor"
          >{{ valor }}</a
        >
        <hr class="mt24 mb24 w-100" />
        <strong
          class="cor-black s10 fw500 mb16"
          *ngIf="veiculo.valor as valor"
          >{{ currencyValue(valor) }}</strong
        >

        <app-botao-whatsapp></app-botao-whatsapp>

        <a
          class="flex flex-items-center pt16 pb32 fw500 udl press cor-primary hv-cor-secondary s3 link-externo"
          href="https://vamos.bancobbcdigital.com.br/"
          target="_blank"
          title="Simulador Banco BBC Digital e Vamos"
        >
          <img
            class="mr8"
            src="/assets/images/banco-bbc-digital.svg"
            alt=""
            width="24px"
            height="24px"
          />
          Simule as parcelas agora
        </a>

        <div class="buttons-row">
          <app-botao-tipo1
            class="redondo"
            [cor2]="'var(--primary)'"
            [cor1]="'var(--white)'"
            href="#formulario-interesse-seminovo"
            label="Tenho Interesse"
          >
          </app-botao-tipo1>

          <app-botao-tipo1
            *ngIf="agendamentoEmbed"
            class="redondo"
            [cor2]="'var(--primary)'"
            [cor1]="'var(--white)'"
            label="Agendar videochamada"
            href="#agendar-videochamada"
          >
          </app-botao-tipo1>
        </div>
      </div>

      <a
        class="flex cor-primary s2 hv-cor-black udl mb16"
        [appStrapilink]
        href="#formulario-nao-encontrei-seminovo"
        >Não conseguiu encontrar o que procura?</a
      >

      <p class="cor-black s2 mb24">
        Precisando comprar, vender, alugar ou até trocar caminhões, implementos,
        máquinas e equipamentos? A Vamos é a solução para você.
      </p>
    </div>
  </div>

  <div class="comunicado mt64 w-100">
    <p
      *ngIf="veiculo.categoria == 'SEMPRE NOVO'; else rest"
      class="cor-black s2 mb24"
    >
      Garantia de 90 dias para o trem de força (que inclui motor, diferencial e
      câmbio) de todos os seminovos.
    </p>

    <ng-template #rest>
      <p *ngIf="veiculo.tipoAquisicao == 'ATIVO'" class="cor-black s2 mb24">
        Veículos seminovos desmobilizados de operações de grandes empresas como:
        JSL, CS Brasil e Grupo Vamos. Com idade média de 5 a 7 anos e garantia
        de procedência. <br />*Apesar de terem recebido manutenções durante o
        contrato com estas empresas, os veículos são comercializados conforme
        estado presente, não cabendo garantia ou manutenções de responsabilidade
        da Vamos Seminovos.
      </p>
      <p *ngIf="veiculo.tipoAquisicao == 'VAREJO'" class="cor-black s2 mb24">
        Veículos seminovos com garantia e procedência. Permite negociação de
        veículos na troca por um seminovo, além de varias opções de
        financiamento. <br />*A compra e venda de caminhões nesta modalidade
        integra o código de defesa do consumidor.
      </p>

      <p class="cor-black s2">
        <b
          >ATENÇÃO: Todos os preços, características, especificações ou
          declarações do veiculo estão sujeitos a alteração sem aviso prévio.</b
        >
      </p>
    </ng-template>
  </div>

  <div *ngIf="loadingRecommended" style="padding: 15px">
    <app-loading></app-loading>
  </div>

  <div *ngIf="recommendedVehicles.length" style="margin-top: 4rem">
    <h3 class="mb24 cor-primary" style="font-size: 1.8rem">
      Quem viu esse veículo, também se interessou por:
    </h3>
    <div class="veiculos-recomendados">
      <app-offer-card
        (click)="clickCard()"
        *ngFor="let veiculo of recommendedVehicles"
        [produtofiltro]="veiculo"
      ></app-offer-card>
    </div>
  </div>
</div>

<app-modal #o id="formulario-interesse-seminovo">
  <div head>
    <p class="s10 fw600 cor-primary upc">Tenho interesse</p>
    <p class="s4 fw400 pt8" *ngIf="veiculo?.descricao as nomeveiculo">
      Gostou do <b>{{ nomeveiculo }}</b
      >? <br />Preencha o formulário que nós entraremos em contato.
    </p>
  </div>
  <div body>
    <app-form-seminovos-tenho-interesse
      *ngIf="!o.hidden"
      (after-if)="
        this.dataLayerService.push({
          event: 'iniciar_modal',
          nome_modal: o.id,
          nome_veiculo: veiculo.descricao
        })
      "
      identifier="formulario-interesse-seminovo"
      [produto]="veiculo"
      class=""
      style="display: block"
      (enviado)="o.fechar()"
    ></app-form-seminovos-tenho-interesse>
  </div>
</app-modal>

<app-modal #x id="agendar-videochamada" *ngIf="agendamentoEmbed">
  <div head>
    <p class="s10 fw600 cor-primary upc">Agendar vídeochamada</p>
    <p class="s4 fw400 pt8">
      Agende sua vídeochamada com o gerente da filial e tire suas dúvidas
    </p>
  </div>
  <div body style="height: 600px">
    <iframe
      [src]="agendamentoEmbed | safe"
      width="100%"
      height="100%"
      scrolling="yes"
      style="border: 0; overflow: scroll"
    ></iframe>
  </div>
</app-modal>

<app-modal id="formulario-nao-encontrei-seminovo" a="false" #q>
  <div head>
    <p class="s10 fw600 cor-primary upc">Não encontrei o que procurava</p>
    <p class="s4 fw400 pt8" *ngIf="veiculo?.descricao as nomeveiculo">
      Preencha o formulário para tentaremos te ajudar a encontrar exatamente o
      que procura.
    </p>
  </div>
  <div body>
    <app-form-seminovos-tenho-interesse
      *ngIf="!q.hidden"
      (after-if)="
        this.dataLayerService.push({
          event: 'iniciar_modal',
          nome_modal: q.id,
          nome_veiculo: veiculo.descricao
        })
      "
      identifier="formulario-nao-encontrei-seminovo"
      class=""
      style="display: block"
      (enviado)="q.fechar()"
    ></app-form-seminovos-tenho-interesse>
  </div>
</app-modal>
