import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { of, delay, tap, Subscription } from "rxjs";
import { ProdutoPdv } from "src/models/ProdutoPdv";
import { IOfferCard } from "src/models/zeus";
import { SwiperComponent } from "swiper/angular";

@Component({
  selector: "app-offer-card",
  templateUrl: "./offer-card.component.html",
  styleUrls: ["./offer-card.component.scss"],
})
export class OfferCardComponent implements OnDestroy {
  public slideAtivo = false;
  public subscription!: Subscription;

  @Input() public offerCard!: IOfferCard;

  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;

  @Input() public set produtofiltro(veiculopdv: ProdutoPdv) {
    this.offerCard = {
      id: veiculopdv.codigo,
      description: veiculopdv.marca,
      title: veiculopdv.modelo,
      price: veiculopdv.valor,
      link: {
        target: "_self",
        href: veiculopdv.url,
      },
      activelabel: veiculopdv.tipoAquisicao,
      features: [
        {
          image: { data: { attributes: { url: "ico-location.svg" } } },
          text: `${veiculopdv.filial?.cidade ?? ""} ${
            veiculopdv.filial?.uf ? "(" + veiculopdv.filial?.uf + ")" : ""
          }`.trim(),
        },
        {
          image: { data: { attributes: { url: "ico-km.svg" } } },
          text: Number(veiculopdv.km).toLocaleString() + " km",
        },
        {
          image: { data: { attributes: { url: "ico-data.svg" } } },
          text: veiculopdv.ano,
        },
      ],
      images: veiculopdv.imagens
        ? veiculopdv.imagens.split(",").map((image) => image.trim())
        : ["/assets/images/veiculo-preparacao.jpeg"],
      campanhas: veiculopdv.campanhas
        ? veiculopdv.campanhas.split(",")
        : [veiculopdv.campanhas].filter(Boolean),
    } as any;

    this.offerCard.features = this.offerCard.features.filter((x) => x.text);
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public turnOnSwiper() {
    if (!this.slideAtivo) {
      this.slideAtivo = true;
      this.subscription = of(true)
        .pipe(
          delay(500),
          tap(() => {
            this.swiper?.swiperRef.slideNext();
          })
        )
        .subscribe();
    }
  }

  public currencyValue(value: number | undefined) {
    if (value != undefined) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    }
    return value;
  }
}
