import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, HostListener } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { distinctUntilChanged, filter, map, shareReplay } from "rxjs";
import { DataLayerService } from "./services/data-layer.service";
import { ModalService } from "./services/modal.service";
import { TradutorService } from "./services/tradutor.service";
import { GlobalStateService } from "./services/global-state.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Grupo Vamos";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private tradutorService: TradutorService,
    public modalService: ModalService,
    public dataLayerService: DataLayerService,
    private router: Router,
    private swUpdate: SwUpdate,
    private globalStateService: GlobalStateService
  ) {
    tradutorService.locale$.subscribe((language) => {
      this.document.documentElement.lang = language;
    });

    this.urlMudou.subscribe((url) => {
      if (url.indexOf("/seminovos/produto/") > -1) {
        const nome_produto = url.split("/")[3];
        dataLayerService.push({
          event: "virtual_page_view",
          virtual_url: url,
          nome_produto: nome_produto,
        });
      } else {
        dataLayerService.push({ event: "virtual_page_view", virtual_url: url });
      }
    });
  }
  ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then((temVersaoNova) => {
        if (temVersaoNova) {
          this.swUpdate.activateUpdate().then((atualizadoComSucesso) => {});
        }
      });
    }
    this.onResize();
  }

  //See the window size, and if its mobile or tablet, change the Mobile status to true
  @HostListener("window:resize") onResize() {
    if (globalThis?.window?.screen.width <= 991) {
      this.globalStateService.changeToMobile(true);
    } else {
      this.globalStateService.changeToMobile(false);
    }
  }

  urlMudou = this.router.events.pipe(
    filter((e): e is NavigationEnd => e instanceof NavigationEnd),

    map((navigationEnd: NavigationEnd) => {
      return navigationEnd.urlAfterRedirects;
    }),
    shareReplay(),
    distinctUntilChanged()
  );
}
