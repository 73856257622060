<div
  class="container container-intra"
  [class.white-bg]="props.backgroundColor === 'white'"
>
  <h3 class="title">{{ props.title }}</h3>
  <div class="content" [class.inverse]="!props.imageBeforeText">
    <img
      [src]="apiUrl + props.image.data.attributes.url"
      alt="image with list"
    />
    <div class="list">
      <div class="bullet-point" *ngFor="let text of props.list">
        <img
          src="../../../../assets/images/pin.png"
          style="width: 15px; height: 20px"
        />
        {{ text }}
      </div>
      <div class="flex flex-wrap" style="gap: 16px" *ngIf="props.link">
        <app-botao-tipo1
          class="redondo"
          [strapilink]="props.link"
          cor1="var(--white)"
          [cor2]="props.link.color === 'red' ? 'red' : 'var(--primary)'"
          [full]="true"
        ></app-botao-tipo1>
      </div>
    </div>
  </div>
  <div class="after-text" *ngIf="props.afterText">
    <markdown
      ngPreserveWhitespaces
      style="font-size: 1.1rem; line-height: 3rem"
    >
      {{ props.afterText }}
    </markdown>
  </div>
</div>
