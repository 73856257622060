import { Component, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { BlockBannerText } from "src/models/zeus/block/block-banner-text";

@Component({
  selector: "app-block-banner-text",
  templateUrl: "./block-banner-text.component.html",
  styleUrls: ["./block-banner-text.component.scss"],
})
export class BlockBannerTextComponent implements OnInit {
  apiUrl = environment.API_URL;
  @Input() props: BlockBannerText = {
    text: "",
    banner: {
      data: {
        attributes: {
          url: "",
        },
      },
    },
  };

  constructor() {}

  ngOnInit() {}
}
